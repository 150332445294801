import { Box, TextField, Button } from "@mui/material";
import InnerBox from "../components/InnerBox";
import PageContainer from "../components/PageContainer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../services/apiclient";
import transformKeys from "../utils/keyUtils";
import AlertPopup from "../components/AlertPopup";

function CommunicationManagementPage() {
  const { eventURN, id: stringId } = useParams();
  const [registrationMsg, setRegistrationMsg] = useState("");
  const [registrationMsgError, setRegistrationMsgError] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  useEffect(() => {
    apiClient.get(`/manage/${eventURN}/communication`).then((response) => {
      let communicationData = transformKeys(response.data);
      setRegistrationMsg(communicationData.registrationMessage);
    });
  }, []);
  useEffect(() => {
    if (registrationMsg.split("\n").length > 20) {
      setRegistrationMsgError("Het bericht mag maximaal 20 regels bevatten.");
    } else {
      setRegistrationMsgError("");
    }
    console.log(registrationMsg.split("\n").length);
  }, [registrationMsg]);

  const handleSubmit = (mode, testMail = false) => {
    switch (mode) {
      case "registration message":
        apiClient
          .post(
            `/manage/${eventURN}/communication/registration-message?test_mail=${testMail}`,
            { message: registrationMsg }
          )
          .then((response) => {
            setAlert({
              open: true,
              message: testMail
                ? "test e-mail verstuurd"
                : "bericht opgeslagen",
              severity: "success",
            });
          });
    }
  };

  return (
    <PageContainer>
      <h1>Communicatiebeheer</h1>
      <InnerBox>
        <h3>bericht bij inschrijving</h3>
        <p>
          Dit bericht wordt toegevoegd aan de e-mail die vrijwilligers ontvangen
          na hun registratie.
        </p>
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            display: "grid",
            gap: "1rem",
            gridTemplateColumns: "auto 1fr",
            justifyItems: "end",
          }}
        >
          <TextField
            sx={{
              gridColumn: "1/4",
            }}
            fullWidth
            error={!!registrationMsgError}
            helperText={registrationMsgError}
            multiline
            maxRows={5}
            value={registrationMsg}
            onChange={(e) => setRegistrationMsg(e.target.value)}
          />

          <Button
            sx={{
              width: "8rem",
              backgroundColor: "#969696FF",
              gridColumn: "2",
              "&:hover": {
                backgroundColor: "#656565FF",
              },
            }}
            variant="contained"
            onClick={() => handleSubmit("registration message", true)}
          >
            Test e-mail
          </Button>
          <Button
            sx={{ width: "8rem", gridColumn: "3" }}
            color="primary"
            variant="contained"
            onClick={() => handleSubmit("registration message")}
          >
            Opslaan
          </Button>
        </Box>
      </InnerBox>
      <AlertPopup alert={alert} setAlert={setAlert} />
    </PageContainer>
  );
}
export default CommunicationManagementPage;
