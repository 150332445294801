import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import apiClient from "../services/apiclient";
import PageContainer from "../components/PageContainer";
import {
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  Fade,
  Divider,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import transformKeys from "../utils/keyUtils";
import dayjs from "dayjs";
function EventDetailsPage() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(UserContext);
  const { eventURN } = useParams();
  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventStart, setEventStart] = useState();
  const [eventEnd, setEventEnd] = useState();
  const [shifts, setShifts] = useState();
  const [eventLocation, setEventLocation] = useState();
  const [loading, setLoading] = useState(true);
  const [eventNotFound, setEventNotFound] = useState(false);
  const [registered, setRegistered] = useState(false);
  useEffect(() => {
    const fetchData = () => {
      apiClient
        .get(`/events/public/${eventURN}`)
        .then((response) => {
          console.log("response:", response.data);
          setEventName(response.data.name);
          setEventDescription(response.data.description);
          if (response.data.street) {
            setEventLocation(
              `${response.data.street} ${response.data.number}, ${response.data.postal_code} ${response.data.city}`
            );
          } else {
            setEventLocation(
              response.data.postal_code + " " + response.data.city
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setEventNotFound(true);
          }
        });

      apiClient
        .get(`/events/public/${eventURN}/volunteer-shifts`)
        .then((response) => {
          setShifts(transformKeys(response.data));
          console.log(response.data);
          setRegistered(true);
        })

        .catch((err) => {
          if (err.response.status === 404) {
            setRegistered(false);
          }
        });
      setLoading(false);
    };
    fetchData();
  }, []);
  console.log(isLoggedIn);
  return (
    <PageContainer>
      {!eventNotFound && !loading && (
        <Box
          sx={{
            maxWidth: "40rem",
            margin: "0 2rem",
            display: "flex",
            textAlign: "center",

            textSpacingTrim: "space-first",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            "& h1": {
              lineHeight: "2rem",
            },
          }}
        >
          <h1>{eventName}</h1>
          <Typography
            variant="p"
            sx={{
              textAlign: "justify",
            }}
          >
            {eventDescription}
          </Typography>
          <Box
            sx={{
              borderTop: "1px solid lightgray ",
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 10fr",
              gap: ".5rem",
              padding: "1rem",
              justifyItems: "start",
              alignItems: "start",
            }}
          >
            <LocationOnIcon />
            <Typography>{eventLocation}</Typography>
          </Box>
          {registered && (
            <Typography
              variant="p"
              sx={{
                fontWeight: "bold",
                color: "var(--black)",
              }}
            >
              U bent reeds geregistreerd voor dit evenement.
            </Typography>
          )}
          {shifts && (
            <Box
              sx={{
                gridTemplateColumns: "1fr 1fr .2fr 1fr",
                display: "grid",
                width: "80%",
              }}
            >
              <Divider
                sx={{
                  gridColumn: "1/5",
                }}
                variant="middle"
              />
              {Object.entries(shifts).map(([date, dayShifts]) => (
                <>
                  {/* Date Header (Spanning Full Grid) */}

                  <Typography
                    fontWeight="bold"
                    sx={{ gridColumn: "1/5", mt: 2 }}
                  >
                    {dayjs(date).format("dddd, D/MM/YYYY")}
                  </Typography>

                  {/* Shifts for the Day */}
                  {dayShifts
                    .sort(
                      (a, b) =>
                        dayjs(a.start).valueOf() - dayjs(b.start).valueOf()
                    )
                    .map((shift) => (
                      <>
                        <Typography fontWeight={"bold"}>
                          {shift.postName}
                        </Typography>
                        <Typography>
                          {dayjs(shift.start).format("HH:mm")}
                        </Typography>
                        <Typography>-</Typography>
                        <Typography>
                          {dayjs(shift.end).format("HH:mm")}
                        </Typography>
                      </>
                    ))}
                </>
              ))}
            </Box>
          )}
          {registered ? (
            <Box>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: "fit-content",
                }}
                onClick={() => navigate("register")}
              >
                inschrijving bijwerken
              </Button>
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "fit-content",
              }}
              onClick={
                isLoggedIn
                  ? () => navigate("register")
                  : () => navigate("/login")
              }
            >
              {isLoggedIn ? "inschrijven" : "inloggen om in te schrijven"}
            </Button>
          )}
        </Box>
      )}
      <Snackbar
        open={eventNotFound}
        autHideDuration={6000}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          Het event werd niet gevonden. Mogelijks staat dit evenement privé.
          Probeer een andere URL of neem contact op met de organisator.
        </Alert>
      </Snackbar>
    </PageContainer>
  );
}
export default EventDetailsPage;
