import { useEffect, useState } from "react";
import PageContainer from "../components/PageContainer";
import apiClient from "../services/apiclient";
import { Box, Button, TextField } from "@mui/material";
import transformKeys from "../utils/keyUtils";
import MuiPhoneNumber from "mui-phone-number";
import { DatePicker } from "@mui/x-date-pickers";
import AlertPopup from "../components/AlertPopup";
import dayjs from "dayjs";
function ProfilePage() {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    apiClient.get("/users/me").then((response) => {
      const data = transformKeys(response.data);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);
      setPhoneNumber(data.phoneNumber);
      setDateOfBirth(dayjs(data.dateOfBirth));

      console.log(response.data);
      setLoading(false);
    });
  }, []);
  console.log(firstName, lastName, email, phoneNumber, dateOfBirth);
  const handleEditClick = () => {
    if (editMode) {
      apiClient
        .post("/users/update-profile", {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: phoneNumber,
          date_of_birth: dayjs(dateOfBirth).format("YYYY-MM-DD"),
        })
        .then(() => {
          setAlert({
            open: true,
            message: "profiel succesvol aangeepast",
            severity: "success",
          });
        })
        .catch((err) => {
            setAlert({
              open: true,
              message: "Er is een fout opgetreden bij het opslaan van het profiel",
              severity: "error",
            });
  
        });
    }
    setEditMode(!editMode);
  };
  return (
    <PageContainer>
      <h1>MIJN PROFIEL</h1>
      {!loading && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { md: "1fr 2fr" },
            margin: "2rem 0",
            "& p": {
              fontWeight: "bold",
            },
            "& *": {
              marginBottom: "0",
            },
            gap: { xs: "0rem 1rem", md: "1rem" },
          }}
        >
          <p>voornaam</p>
          <TextField
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            disabled={!editMode}
          />
          <p>achternaam</p>
          <TextField
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            disabled={!editMode}
          />
          <p>e-mailadres</p>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!editMode}
          />
          <p>telefoonnummer</p>
          <MuiPhoneNumber
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            disabled={!editMode}
          />
          <p>geboortedatum</p>
          <DatePicker
            variant="outlined"
            value={dayjs(dateOfBirth)}
            onChange={(e) => setDateOfBirth(e)}
            disabled={!editMode}
          />
          <Button
            sx={{
              marginTop: "1rem",
              gridColumn: { xs: "1", md: "1/3" },
            }}
            variant="contained"
            onClick={handleEditClick}
          >
            {editMode ? "Opslaan" : "Bewerken"}
          </Button>
        </Box>
      )}
      <AlertPopup alert={alert} setAlert={setAlert} />
    </PageContainer>
  );
}

export default ProfilePage;
