import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import apiClient from "../services/apiclient";
import { Box, Button } from "@mui/material";
import InnerBox from "../components/InnerBox";
function EventsPage() {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    const fetchData = () => {
      apiClient.get("/events/public").then((response) => {
        setEvents(response.data);
      });
    };
    fetchData();
  }, []);
  return (
    <PageContainer>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: { xs: "center", md: "flex-start"},
          alignContent: { xs: "center", md: "flex-start" },
        }}
      >
        {events.map((event) => {
          return (
            <InnerBox
              width={{
                xs: "80%",
                md: "20rem",
              }}
            >
              <h3>{event.name}</h3>

              <p>{event.description}</p>
              <Link to={`/events/${event.urn}`}>
                <Box
                  sx={{
                    borderTop: "1px solid gray",
                  }}
                ></Box>
                <Button variant="contained">Inschrijven</Button>
              </Link>
            </InnerBox>
          );
        })}
      </Box>
    </PageContainer>
  );
}

export default EventsPage;
